import React from "react";
import { Header, Paragraph } from "../styles/StyledComponents";

const Experience = () => {
    return (
        <div>
            <Header>Experience</Header>
            <Paragraph>This is the experience page</Paragraph>
        </div>
    );
}

export default Experience;