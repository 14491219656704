import React from "react";

const Contact = () => {
    return (
        <div>
            <h1>Contact</h1>
            <p>This is the contact page</p>
        </div>
    );
}

export default Contact;